<template>
  <section class="news-detail">
    <div v-if="!ifPhone">
      <div class="banner">
        <div class="content">
          <div class="boxs">
            <el-carousel :height="bannerHeight" :interval="4000">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <img class="cp" @click="Skip(item.href)" :src="item.url" :alt="item.name" style="width: 100%; height: 100%">
                </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="mb20 mt20">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_fs">
              <el-breadcrumb-item :to="{ path: '/information' }">{{node.newsTranslate}}</el-breadcrumb-item>
              <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="news-detail__body">
          <div>
            <div class="news-detail__body__title lc main_body_title">
              {{title}}
              <span class="news-detail__body__date fr">{{node.releaseTimeTranslate}}: {{createTime.slice(0, 11)}}</span>
            </div>
            <div class="news-detail__body__ctn rich_text main_body" v-html="bizDetail"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="ifPhone" class="phone">
      <div class="banner">
        <div class="content">
          <div class="boxs">
            <el-carousel :height="bannerHeight" :interval="4000">
                <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                    <img class="cp" @click="Skip(item.href)" :src="item.url" :alt="item.name" style="width: 100%; height: 100%">
                </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="mb20" style="margin-top: 100px">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_fs">
              <el-breadcrumb-item :to="{ path: '/information' }">{{node.newsTranslate}}</el-breadcrumb-item>
              <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="news-detail__body">
          <div>
            <div class="news-detail__body__title lc main_body_title">
              {{title}}
              <span class="news-detail__body__date fr">{{node.releaseTimeTranslate}}: {{createTime.slice(0, 11)}}</span>
            </div>
            <div class="news-detail__body__ctn rich_text main_body" v-html="bizDetail"></div>
          </div>
        </div>
      </div>
    </div>
    <phone-menu></phone-menu>
  </section>
</template>

<script>
  import phoneMenu from '../components/phoneMenu.vue'
  import { sitenewsInfo } from '@/api/index'
  export default {
    name: "newsDetail",
    data() {
      return {
        bannerHeight: '540px',
        bannerList: [],
        title: '',
        createTime: '',
        bizDetail: '',
        newsIdx: this.$route.query.id,
        ifPhone: false
      }
    },
    created() {
      this.getInfo()
    },
    components: {
      phoneMenu
    },
    computed: {
      node(){
        return this.$store.state.node
      }
    },
    mounted() {
      this.bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight + 'px'
      let maxResult = window.matchMedia('(max-width: 980px)');
      this.ifPhone = false
      if (maxResult.matches) {
        var h =window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        // let f = document.getElementsByClassName('phone_footer')[0].offsetHeight
        // let he = document.getElementsByClassName('p_header')[0].offsetHeight
        // document.getElementsByClassName('news-detail')[0].style.minHeight = (h - f - he - 2) + 'px'
        this.bannerHeight = '200px'
        this.ifPhone = true
      }
    },
    methods: {
      getInfo() {
        let id = this.newsIdx
        sitenewsInfo(id).then((res) => {
          if(res && res.code == 0) {
            let imgs = JSON.parse(res.data.imgs)
            this.bannerList = []
            imgs.map(item => {
              this.bannerList.push({
                url: this.imgTransition(item.urlName),
                name: item.urlName,
                href: item.href
              })
            })
            this.title = res.data.title
            this.createTime = res.data.createTime
            this.bizDetail = res.data.bizDetail
          }
        }).catch(err => {
          console.log('err', err);
        })
      },
      Skip(url) {
        if(url) {
          window.open(url)
        }
      },
      // 图片路径转换
      imgTransition(str) {
        return this.$store.state.domainImg + str.slice(0, 1) + '/' + str.slice(1, 2) + '/' + str
      },
    }
  }
</script>

<style lang="scss" scoped>
@media screen and (max-width: 980px) {
  .news-detail {
    &__body {
      width: 90vw !important;
    }
  }
  .rich_text {
    p {
      img {
        width: 80% !important;
        height: auto;
      }
    }
  }
}
::v-deep.el-breadcrumb {
    font-size: 26px;
}
  .news-detail {
    background: #f2f2f2;
    padding-bottom: 20px;

    &__nav {
      font-size: 16px;
      color: #151515;
      margin-left: 257px;
      margin-bottom: 30px;

      .grey {
        color: #999;
      }
    }

    &__body {
      background: #fff;
      width: 1440px;
      height: auto;
      margin: 0 auto;
      padding: 50px 40px;
      font-size: 16px;
      color: #151515;
      box-sizing: border-box;

      &__title {
        font-weight: bold;
        font-size: 24px;
        overflow: hidden;
      }

      &__date {
        color: #999;
        font-size: 16px;
        margin: 20px 0 36px;
        font-weight: normal;
      }

      img {
        display: block;
        margin: 30px auto;
      }
    }
  }
</style>
